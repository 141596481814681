// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_padrao{
    border-radius: 10px;
    padding: 10px;
    font-size:20px;
    border: none;
    color:white;
    width: 100%;
    height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ButtonPadrao/button.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;AAChB","sourcesContent":[".button_padrao{\n    border-radius: 10px;\n    padding: 10px;\n    font-size:20px;\n    border: none;\n    color:white;\n    width: 100%;\n    height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
