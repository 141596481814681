import './DivError.css'
import React from 'react'

const DivError = ({codeError, textError, imgAlt, imgSrc, imgClassName, textCodeError}) => {

    return(
        <div className='divErrorRow'>
            <div>
                <img className={imgClassName} src={imgSrc} alt={imgAlt}  />
            </div>
            <div className='divErrorColumn'>
                <div className='text16'><b>{textError}</b></div>
                <div className='text20'><b>{codeError} - {textCodeError}</b></div>
            </div>
        </div>
    )

}

export default DivError