import React, { createContext, useState, useEffect } from 'react'
export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || null)
 
  useEffect(() => {
    //console.log('accessToken em USEEFECT:', accessToken)
  }, [accessToken])

  const login = (token) => {
    setAccessToken(token)
    localStorage.setItem('accessToken', token)
  }

  const logout = () => {
    setAccessToken(null)
    localStorage.clear()
  }


  return (
    <AuthContext.Provider value={{ accessToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}



