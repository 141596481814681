import React from 'react'

const ItemReturnEdit = ({ onClickFunction, divClassName, divClassNameItem, iconClassName, onClickFunctionExpand, textTitleClassNameExpand, textTitleExpand, divClassNameItem2, iconLeftUrl, iconLeftAlt, textTitleClassName, textTitle, iconRightUrl, iconRightAlt, textButtonTitleClassName, textButtonTitle}) => {
  

    return <div className={divClassName}>
        <div className={divClassNameItem}> 
            <img className={iconClassName} src={iconLeftUrl} alt={iconLeftAlt} />
            <div className={textTitleClassName}>{textTitle}</div>
        </div>
        <div className={textTitleClassNameExpand} onClick={onClickFunctionExpand}>{textTitleExpand}</div>
        <div className={divClassNameItem2} onClick={onClickFunction} >
            <img className={iconClassName} src={iconRightUrl} alt={iconRightAlt} />
            <div className={textButtonTitleClassName}>{textButtonTitle}</div>
        </div>
    </div>
}

export default ItemReturnEdit


