// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.div_phrase{
    font-size:16px;
    width: 90%;
    padding-right:30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PhraseInsert/phrase.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,UAAU;IACV,kBAAkB;AACtB","sourcesContent":["\n.div_phrase{\n    font-size:16px;\n    width: 90%;\n    padding-right:30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
