import React from 'react'
import './inputbrancocinzagrande.css'

const Input = ({ type, placeholder, value, onChange, borderColor, borderRange, accept, inputRef }) => {
  const InputStyle = {
    border: `${borderRange} solid ${borderColor}`
  }
  if (type === 'textarea') {
    return (
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className='input_branco_cinza'
        style={InputStyle}
      />
    )
  }

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className='input_branco_cinza'
      style={InputStyle}
      accept={accept}
      ref={inputRef}
    />
  )
}

export default Input