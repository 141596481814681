// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.div_menumobile_general{
    display: none;
}

@media (max-width: 768px) {
    .div_menumobile_general{
        display: flex;
        flex-direction: column;
        gap:24px;
        width: 100%;
        align-items: center;
        background-color: #222323;
        color: white;
        padding:20px;
    }
    
    .div_menumobile_general_buttons{
        display: flex;
        flex-direction: column;
        gap:16px;
        text-align: center;
        align-items: center;
        font-size: 18px;
        padding: 16px 0px 0px 0px;
    }
    
    
    .div_menumobile_general_buttons a {
        text-decoration: none; /* Remove o sublinhado */
        color: white; /* Define a cor do texto como desejado */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/EnzoMenuMobile/menumobile.css"],"names":[],"mappings":";AACA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,QAAQ;QACR,WAAW;QACX,mBAAmB;QACnB,yBAAyB;QACzB,YAAY;QACZ,YAAY;IAChB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,QAAQ;QACR,kBAAkB;QAClB,mBAAmB;QACnB,eAAe;QACf,yBAAyB;IAC7B;;;IAGA;QACI,qBAAqB,EAAE,wBAAwB;QAC/C,YAAY,EAAE,wCAAwC;IAC1D;AACJ","sourcesContent":["\n.div_menumobile_general{\n    display: none;\n}\n\n@media (max-width: 768px) {\n    .div_menumobile_general{\n        display: flex;\n        flex-direction: column;\n        gap:24px;\n        width: 100%;\n        align-items: center;\n        background-color: #222323;\n        color: white;\n        padding:20px;\n    }\n    \n    .div_menumobile_general_buttons{\n        display: flex;\n        flex-direction: column;\n        gap:16px;\n        text-align: center;\n        align-items: center;\n        font-size: 18px;\n        padding: 16px 0px 0px 0px;\n    }\n    \n    \n    .div_menumobile_general_buttons a {\n        text-decoration: none; /* Remove o sublinhado */\n        color: white; /* Define a cor do texto como desejado */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
