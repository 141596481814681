
import './EnzoStart.css'
import EnzoMenu from '../../components/EnzoMenu'
import EnzoMenuMobile from '../../components/EnzoMenuMobile'
import { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import Img from '../../components/Img'
import imgLogoBranca from '../img/young_branco.png'
import iconEmail from '../../icons/whitemail.png'
import iconCalendar from '../../icons/whitecalendar.png'

const EnzoStart = () => {
    /* VARIAVEIS */
    const { accessToken } = useContext(AuthContext)
    const navigate = useNavigate()
    const [contentReturnUser, setContentReturnUser] = useState(null)
    const [showEdit, setShowEdit] = useState(false)
    const [divPutName, setDivPutName] = useState(false)
    const [divPutEmail, setDivPutEmail] = useState(false)
    const [divPutPsw, setDivPutPsw] = useState(false)
    const [putName, setPutName] = useState(null)
    const [putEmail, setPutEmail] = useState(null)
    const [putPsw, setPutPsw] = useState(null)
    const [putConfirmPsw, setPutConfirmPsw] = useState(null)
    const [pswMatch, setPswMatch] = useState(false)
    const [showNotificationCompanie, setShowNotificationCompanie] = useState(false)
    const [showAlertCompanie, setShowAlertCompanie] = useState(false)

	const [notificationContent, setNotificationContent] = useState(null)
	const [alertContent, setAlertContent] = useState(null)
    

	
	
    useEffect(() => {

        if (showNotificationCompanie) {
        const timeoutId = setTimeout(() => {
            setShowNotificationCompanie(false)
            setNotificationContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showNotificationCompanie])


    useEffect(() => {

        if (showAlertCompanie) {
        const timeoutId = setTimeout(() => {
            setShowAlertCompanie(false)
            setAlertContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showAlertCompanie])
	
    /* VERIFICAÇÃO DE ACESSO */
    if (!accessToken) {
        return <Navigate to="/login" />
    }
    
    
    useEffect(() => {
        if (accessToken) {
            axios.get(`${process.env.REACT_APP_REQ}user/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(response => {
                //console.log('Deu bom, response:', response.data)
                setContentReturnUser(response.data)
            })
            .catch(error => {
                console.error('DEV - Erro na solicitação GET para clientdata:', error)
                if (error.response && error.response.status === 401) {
                    navigate('/logout')
                }
                navigate('/login')
            })
        }
    }, [accessToken])
    


    function formatDate(dataString) {
        const data = new Date(dataString)
        const dia = data.getDate().toString().padStart(2, '0')
        const mes = (data.getMonth() + 1).toString().padStart(2, '0')
        const ano = data.getFullYear()
    
        return `${dia}/${mes}/${ano}`
    }

    const functionGetUpdate = () => {
        if (accessToken) {
            axios.get(`${process.env.REACT_APP_REQ}user/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(response => {
                //console.log('Deu bom, response:', response.data)
                setContentReturnUser(response.data)
            })
            .catch(error => {
                console.error('DEV - Erro na solicitação GET para clientdata:', error)
                if (error.response && error.response.status === 401) {
                    navigate('/logout')
                }
                navigate('/login')
            })
        }
    }

    const handleOpenEditInfos = () => {
        setShowEdit(!showEdit)
    }

    
    const functionShowPutName = () => {
        setDivPutName(true)
        setDivPutEmail(false)
        setDivPutPsw(false)
        setPutEmail(null)
        setPutPsw(null)
        setPutConfirmPsw(null)
        // zerar variaveis
    }

    const functionShowPutEmail = () => {
        setDivPutEmail(true)
        setDivPutName(false)
        setDivPutPsw(false)
        setPutName(null)
        setPutPsw(null)
        setPutConfirmPsw(null)
        // zerar variaveis
    }

    const functionShowPutPsw = () => {
        setDivPutPsw(true)
        setDivPutEmail(false)
        setDivPutName(false)
        setPutName(null)
        setPutEmail(null)
        // zerar variaveis
    }

    const ConfirmEqualPsw =  (e) => {
        setPutConfirmPsw(e.target.value)

        if(putPsw === e.target.value){ 
            setPswMatch(false)
        }else{
            setPswMatch(true)
        }

    }

    const functionResetVariablesPut = () => {
        setShowEdit(false)
        setDivPutName(false)
        setDivPutEmail(false)
        setDivPutPsw(false)
        setPutName(null)
        setPutEmail(null)
        setPutPsw(null)
        setPutConfirmPsw(null)
        setPswMatch(false)
    }

    const functionPutName = async() => {

        if(!putName){
            setAlertContent('🔔 Verificar: Nome!')
            setShowAlertCompanie(true)
            //alert('Você não informou um novo nome, favor verificar!')
            return
        }
        const data = {
            name : putName
        }
        //console.log('DEV - Início PUT NAME', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}user`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                setNotificationContent('🔔 Alterado com sucesso!')
                setShowNotificationCompanie(true)
                //alert('Alterado com sucesso!')
                functionGetUpdate()
                functionResetVariablesPut()
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM  PUT NAME')
        }
    }

    const functionPutEmail = async() => {

        if(!putEmail){
            setAlertContent('🔔 Verificar: Novo e-mail!')
            setShowAlertCompanie(true)
            //alert('Você não informou um novo nome, favor verificar!')
            return
        }
        const data = {
            email : putEmail
        }
        //console.log('DEV - Início PUT EMAIL', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}user`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                setNotificationContent('🔔 Alterado com sucesso!')
                setShowNotificationCompanie(true)
                functionGetUpdate()
                functionResetVariablesPut()
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM  PUT EMAIL')
        }
    }
    const functionPutPsw = async() => {
        if(!putPsw){
            setAlertContent('🔔 Verificar: Nova senha!')
            setShowAlertCompanie(true)
            //alert('Você não informou uma nova senha, favor verificar!')
            return
        }

        if(!putConfirmPsw){
            setAlertContent('🔔 Verificar: Confirmação nova senha!')
            setShowAlertCompanie(true)
            //alert('Você não confirmou sua nova senha, favor verificar!')
            return
        }

        if(putPsw !== putConfirmPsw){
            setAlertContent('🔔 Senhas não coincidem!')
            setShowAlertCompanie(true)
            //alert('Senhas não coincidem, favor verificar!')
            return
        }
        const data = {
            password : putPsw
        }
        //console.log('DEV - Início PUT SENHA', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}user`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                setNotificationContent('🔔 Alterado com sucesso!')
                setShowNotificationCompanie(true)
                functionGetUpdate()
                functionResetVariablesPut()
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM  PUT SENHA')
        }
    }


    return(
        <div className='enzoStart'>
            <div className='divMenus'>
                <EnzoMenu />
                <EnzoMenuMobile />
            </div>
            <div className='divContent'>
            {contentReturnUser ? (
                <div className='divContentColGap'>
                        <div className='divContentColGapBasic'>
                            <div>
                                <div className='fontSizeW40pxM18px'><b>BEM VINDO(A) {contentReturnUser.name}!</b></div>
                            </div>
                            <div>
                                <div className='fontSizeW20pxM14px'>
                                    <b>Apresentamos o YAN: uma solução que combina a força do ChatGPT com seu treinamento.</b> Nossa integração com o ChatGPT melhora a qualidade do tratamento de dados e permite respostas mais inteligentes aos usuários. <br/><b>Eleve o potencial do seu atendimento com nossa integração de IA.</b>
                                </div>
                                <div className='divContentColGap1'>
                                    <div className='divContentIconInfo'>
                                       <div className='divContentIconInfoItem1'><Img className={'imgWidth40px'} imageUrl={iconCalendar} alt={'Icone calendario'} /></div>
                                       <div className='divContentIconInfoItem2'>Data do cadastro: <b>{formatDate(contentReturnUser.inserted)}</b></div>
                                    </div>
                                    <div className='divContentIconInfo'>
                                        <div className='divContentIconInfoItem1'><Img className={'imgWidth40px'} imageUrl={iconEmail} alt={'Icone email'} /></div>
                                        <div className='divContentIconInfoItem2'><b>{contentReturnUser.email}</b></div>
                                    </div>
                                    <div className='clickExpandEdit' onClick={handleOpenEditInfos}><b>ALTERAR INFORMAÇÕES</b></div>
                                    {showEdit &&
                                        <div className='divInputEdit'>
                                            <button className='buttonChoosePut' onClick={functionShowPutName}>N O M E</button>
                                            {divPutName && 
                                                <div className='divInputEdit2'>
                                                    <div className='titleInputEdit'>Insira um novo nome:</div>
                                                    <input className='inputPutDados' type='text' value={putName} placeholder='Digite o novo nome' onChange={(e) => setPutName(e.target.value)}/>
                                                    <button className='buttonPutDados' onClick={functionPutName}><b>ALTERAR</b></button>
                                                </div>
                                            }
                                            
                                            <button className='buttonChoosePut' onClick={functionShowPutEmail}>E M A I L</button>
                                            {divPutEmail && 
                                                <div className='divInputEdit2'>
                                                    <div className='titleInputEdit'>Insira um novo e-mail:</div>
                                                    <input className='inputPutDados' type='text' value={putEmail} placeholder='Digite o novo e-mail' onChange={(e) => setPutEmail(e.target.value)}/>
                                                    <button className='buttonPutDados' onClick={functionPutEmail}><b>ALTERAR</b></button>
                                                </div>
                                            }
                                            <button className='buttonChoosePut' onClick={functionShowPutPsw}>S E N H A </button>
                                            {divPutPsw && 
                                                <div className='divInputEdit2'>
                                                    <div className='titleInputEdit'>Insira uma nova senha:</div>
                                                    <input className='inputPutDados' type='password' value={putPsw} placeholder='Digite sua nova senha' onChange={(e) => setPutPsw(e.target.value)}/>
                                                    <div className='titleInputEdit'>Confirme sua nova senha:</div>
                                                    <input className='inputPutDados' type='password' value={putConfirmPsw} placeholder='Confirme sua nova senha' onChange={ConfirmEqualPsw} />
                                                    {pswMatch && 
                                                        <div>
                                                            Senhas não conferem! :(
                                                        </div>
                                                    }
                                                    <button className='buttonPutDados' onClick={functionPutPsw}><b>ALTERAR</b></button>
                                                </div>
                                            }
                                           
                                        </div>
                                    }
                                </div>
                            </div>
                            {showNotificationCompanie &&
                                <div className='div_Notification'>{notificationContent}</div>
                            }
                            {showAlertCompanie &&
                                <div className='div_Alert'>{alertContent}</div>
                            }
                        </div>
                        <div className='divContentColGapBasic'>
                            <Img className={'imgWidth250px'} imageUrl={imgLogoBranca} alt={'Logo Young'} />
                        </div>
                </div>
            ) : (
                <div>C A R R E G A N D O . . .</div>
            )}
            </div>
        </div>
    )

}

export default EnzoStart

