import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

const EnzoLogout = () => {
  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    // Executar o logout quando o componente for montado
    logout()
    navigate('/')
  }, [logout, navigate])

  return (
    <div>
      <h2>Fazendo logout...</h2>
    </div>
  )
}

export default EnzoLogout
