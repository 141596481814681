// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  font-family: 'Mulish', sans-serif;
}

.App {
  text-align: center;
}

.div_Notification{
  position: fixed;
  top: 10px; /* Posiciona no topo com margem */
  left: 50%;
  transform: translateX(-50%); /* Ajusta a posição horizontalmente */
  background-color: #3a6332;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  padding: 10px 50px; 
  margin: 10px 10px 40px 10px;
  border-radius: 10px;
  font-size: 16px;
  max-width: 50%;
}

.div_Alert{
  position: fixed;
  top: 10px; /* Posiciona no topo com margem */
  left: 50%;
  transform: translateX(-50%); /* Ajusta a posição horizontalmente */
  background-color: #ff6961;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  padding: 10px 50px; 
  margin: 10px 10px 40px 10px;
  border-radius: 10px;
  font-size: 16px;
  max-width: 50%;
}

.selectGeral{
  display: flex;
  width: 80%;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
}


@media (max-width: 768px) {
  .div_Notification{
    max-width: 300px;
    margin:0px;
    padding:10px;
  }

  .div_Alert{
    max-width: 300px;
    margin:10px;
    padding:0px;
  }

  .selectGeral{
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,SAAS,EAAE,iCAAiC;EAC5C,SAAS;EACT,2BAA2B,EAAE,qCAAqC;EAClE,yBAAyB;EACzB,6CAA6C;EAC7C,cAAc;EACd,kBAAkB;EAClB,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,SAAS,EAAE,iCAAiC;EAC5C,SAAS;EACT,2BAA2B,EAAE,qCAAqC;EAClE,yBAAyB;EACzB,6CAA6C;EAC7C,cAAc;EACd,kBAAkB;EAClB,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,aAAa;AACf;;;AAGA;EACE;IACE,gBAAgB;IAChB,UAAU;IACV,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,WAAW;EACb;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":["*{\n  font-family: 'Mulish', sans-serif;\n}\n\n.App {\n  text-align: center;\n}\n\n.div_Notification{\n  position: fixed;\n  top: 10px; /* Posiciona no topo com margem */\n  left: 50%;\n  transform: translateX(-50%); /* Ajusta a posição horizontalmente */\n  background-color: #3a6332;\n  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);\n  color: #ffffff;\n  padding: 10px 50px; \n  margin: 10px 10px 40px 10px;\n  border-radius: 10px;\n  font-size: 16px;\n  max-width: 50%;\n}\n\n.div_Alert{\n  position: fixed;\n  top: 10px; /* Posiciona no topo com margem */\n  left: 50%;\n  transform: translateX(-50%); /* Ajusta a posição horizontalmente */\n  background-color: #ff6961;\n  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);\n  color: #ffffff;\n  padding: 10px 50px; \n  margin: 10px 10px 40px 10px;\n  border-radius: 10px;\n  font-size: 16px;\n  max-width: 50%;\n}\n\n.selectGeral{\n  display: flex;\n  width: 80%;\n  height: 50px;\n  border-radius: 10px;\n  padding: 10px;\n}\n\n\n@media (max-width: 768px) {\n  .div_Notification{\n    max-width: 300px;\n    margin:0px;\n    padding:10px;\n  }\n\n  .div_Alert{\n    max-width: 300px;\n    margin:10px;\n    padding:0px;\n  }\n\n  .selectGeral{\n    width: 90%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
