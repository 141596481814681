import './App.css'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import EnzoLogin from './pages/EnzoLogin'
import StartPage from './pages/StartPage'
import EnzoRegister from './pages/EnzoRegister'
import EnzoCompanie from './pages/EnzoCompanie'
import { AuthProvider, AuthContext, logout } from './context/AuthContext'
import EnzoTalk from './pages/EnzoTalk'
import EnzoLogout from './pages/EnzoLogout'
import EnzoChats from './pages/EnzoChats'
import { useNavigate } from 'react-router-dom'
import EnzoStart from './pages/EnzoStart'

function App() {
  return (
    <AuthProvider>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300&display=swap')
      </style>
      <Router>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/login" element={<EnzoLogin />} />
          <Route path="/create" element={<EnzoRegister />} />
          <Route path="/logout" element={<EnzoLogout />} />
          <Route path="/companie" element={<EnzoCompanie />} />
          <Route path="/home" element={<EnzoStart />}/>
          <Route path="/talk" element={<EnzoTalk />}/>
          <Route path="/showchats" element={<EnzoChats />}/>
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App