import React from 'react';

const Logo = ({ width, imageUrl, alt }) => {
  return (
    <img
      src={imageUrl}
      alt={alt}
      style={{ width: width, height: 'auto' }}
    />
  );
};

export default Logo;