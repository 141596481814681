import './login.css'
import React, { useState, useContext, useEffect } from 'react'
import RoboImg from '../../components/RoboImg'
import robo from '../img/robo1.png'
import loadingRosa from '../img/loading_p_rosa.png'
import logoPreta from '../img/young_preto.png'
import Logo from '../../components/Logo'
import ButtonPadrao from '../../components/ButtonPadrao'
import logoBranca from '../img/young_branco.png'
import Input from '../../components/Input'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Modal, Button } from 'react-bootstrap'
import LoadingImageRotate from '../../components/LoadingImageRotate'
import { useNavigate } from 'react-router-dom'
import TextAlert from '../../components/TextAlert'


const EnzoLogin = () => {
    const [loginEmail, setLoginEmail] = useState(null)
    const [loginPsw, setLoginPsw] = useState(null)
    const [newPsw, setNewPsw] = useState('')
    const [tokenChangePsw, setTokenChangePsw] = useState(null)
    const {login} = useContext(AuthContext)
    const [showModal, setShowModal] = useState(false)
    const [showModalTokenPsw, setShowModalTokenPsw] = useState(false)
    const [isLoadingPsw, setIsLoadingPsw] = useState(false)
    const [isLoadingActivated, setIsLoadingActivated] = useState(false)
    const [newPswCompare, setNewPswCompare] = useState('')
	const [showDivTokenPsw, setShowDivTokenPsw] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [isLoadingChangePsw, setIsLoadingChangePsw] = useState(false)
    const [showNotificationCompanie, setShowNotificationCompanie] = useState(false)
    const [showAlertCompanie, setShowAlertCompanie] = useState(false)

	const [notificationContent, setNotificationContent] = useState(null)
	const [alertContent, setAlertContent] = useState(null)
    const navigate = useNavigate()
    
        // MODAL RECUPERAR SENHA
    const resetModal = () => {
        setShowModal(false)
        setLoginEmail('')
        setTokenChangePsw('')
        setNewPsw('')
        setNewPswCompare('')
        setShowDivTokenPsw(false)
        setPasswordMatch(false)
    }

    const resetModalTokenPsw = () => {
        setLoginEmail('')
        setShowModalTokenPsw(false)
    }

        // REQUISIÇÃO LOGIN
    const handleLoginPost = async () => {
        if(!loginEmail){
            alert('Favor inserir e-mail')
            return
        }
        if(!loginPsw){
            alert('Você não inseriu sua senha!')
            return
        }
        try {
            //('email para requisição:', loginEmail)
            //console.log('password para requisição:', loginPsw)
            const response = await axios.post(`${process.env.REACT_APP_REQ}login`, {
                "email":loginEmail,
                "password":loginPsw
            })
            if (response.status === 200) {
                //console.log('Status requisição login:', response.status)
                //console.log('Return 200 requisição de login (data):', response.data)
                //console.log('Return 200 requisição de login (data.access_token):', response.data.access_token)
                const token = response.data.access_token
                login(token)
                navigate('/home')
            }
        }catch (error) {
            console.log(error)
            alert(error.response.data.msg)
        }finally {
            //console.log('REQUISIÇÃO CONTINUAR - finally: ACABOU inserir aqui definições')
        }
    }




    useEffect(() => {

        if (showNotificationCompanie) {
        const timeoutId = setTimeout(() => {
            setShowNotificationCompanie(false)
            setNotificationContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showNotificationCompanie])


    useEffect(() => {

        if (showAlertCompanie) {
        const timeoutId = setTimeout(() => {
            setShowAlertCompanie(false)
            setAlertContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showAlertCompanie])
	

        // AVALIAÇÃO SE SENHAS INSERIDAS SÃO IGUAIS
    const ConfirmEqualPsw =  (e) => {
        setNewPswCompare(e.target.value)
        
        if(newPsw !== e.target.value){ 
            setPasswordMatch(true)
        }else{
            setPasswordMatch(false)
        }

    }






        // REQUISIÇÃO PARA RECUPERAR DE SENHA
    const handleNewPsw = async () => {
        
        if(!loginEmail){
            setAlertContent('🔔 Verificar: E-mail!')
            setShowAlertCompanie(true)
            //alert('Favor inserir e-mail')
            return
        }

        setIsLoadingPsw(true)

        try {
            const response = await axios.post(`${process.env.REACT_APP_REQ}reset_password/`, {
                "email":loginEmail
            })
            if (response.status === 200) {
                alert(response.data.msg)
                setShowDivTokenPsw(true)
            }
        }catch (error) {
            console.log(error)
            alert(error.response.data.msg)
        }finally {
            setIsLoadingPsw(false)
        }
    }

        // REQUISIÇÃO PARA ALTERAR SENHA
        const handleChangeNewPsw = async () => {
        
            if(!tokenChangePsw){
                setAlertContent('🔔 Verificar: Token!')
                setShowAlertCompanie(true)
                //alert('Favor inserir e-mail')
                return
            }
            if(newPsw !== newPswCompare){
                setAlertContent('🔔 Verificar: Senhas não coincidem!')
                setShowAlertCompanie(true)
                //alert('Senhas inseridas não conferem')
                return
            }
    
            setIsLoadingChangePsw(true)
    
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}reset_password/`, {
                    "token":tokenChangePsw,
                    "new_password": newPsw
                })
                if (response.status === 200) {
                    alert(response.data.msg)
                    resetModal()
                }
            }catch (error) {
                console.log(error)
                console.log(error.response.data.msg)
            }finally {
                setIsLoadingChangePsw(false)
            }
        }





    // REQUISIÇÃO SOLICITAR NOVO EMAIL DE CONFIRMAÇÃO DE CADASTRO
    const handleActivateRegister = async () => {
        if(!loginEmail){
            setAlertContent('🔔 Verificar: E-mail!')
            setShowAlertCompanie(true)
            //alert('Informe o e-mail para receber um novo link de confirmação de cadastro, por favor.')
            return
        }
        setIsLoadingActivated(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_REQ}account_validation`, {
                "email":loginEmail
            })
            if (response.status === 200) {
                //console.log('Return 200 novo email confirmação cadastro', response.data)
                await setNotificationContent('🔔 ', response.data.msg)
                await setShowNotificationCompanie(true)
                //alert(response.data.msg)
            }
        }catch (error) {
            console.log(error)
            alert(error.response.data.msg)
        }finally {
            //console.log('REQUISIÇÃO NOVO EMAIL CONFIRMAÇÃO CADASTRO - finally')
            setIsLoadingActivated(false)
            resetModalTokenPsw()
        }
    }
    


    return (
        <div className='div_login_page'>
            <div className='div_login_page_2'>
                <div className='div_login_page_div_division'>
                    <div className='div_login_page_div_division_1'>
                        <div>
                            <RoboImg imageUrl={robo} alt={'Robo Yan'} width={'120px'} />
                        </div>
                        <div className='div_login_page_div_acess'>
                            <div className='div_login_page_div_text'>
                                <b>Yan, o seu assistente virtual inteligente Young, sempre disponível para ajudá-lo.</b>
                            </div>
                        </div>
                    </div>

                <div className='div_login_page_div_division_2'>
                    <div><Logo width={'200px'} imageUrl={logoPreta} alt={'Logo Young cor Preto'} /></div>
                </div>

                </div>
                
            </div>
            <div className='div_login_page_1'>
                <div className='div_login_page_1_content'>
                    <div className='div_login_page_1_content_img'>
                        <Logo width={'200px'} imageUrl={logoBranca} alt={'Logo Young cor Branco'} />
                    </div>
                    <Input type={'text'} placeholder={'Informe seu e-mail'} borderRange={'2px'} value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} borderColor={'#222323'} />
                    <Input type={'password'} placeholder={'Informe sua senha'} borderRange={'2px'} value={loginPsw} onChange={(e) => setLoginPsw(e.target.value)} borderColor={'#222323'} />
                    <ButtonPadrao backgroundColor={'#6051B7'} onClick={handleLoginPost} text={'ACESSAR'}/>
                    <ButtonPadrao backgroundColor={'#737d81'} text={'RECUPERAR SENHA'} onClick={() => setShowModal(true)}/>
                    <ButtonPadrao backgroundColor={'#737d81'} text={'REENVIAR E-MAIL'} onClick={() => setShowModalTokenPsw(true)}/>
                    {showNotificationCompanie &&
                        <div className='div_Notification'>{notificationContent}</div>
                    }
                    {showAlertCompanie &&
                        <div className='div_Alert'>{alertContent}</div>
                    }
                </div>
            </div>

            



            <Modal show={showModalTokenPsw} onHide={resetModalTokenPsw}>
                <Modal.Header closeButton>
                    <Modal.Title>Solicitar novo e-mail de ativação de conta:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='div_modal_activate'>
                        <div className='div_modal_activate_div'>
                            <div className='div_login_page_div_textSimple'>Informe seu e-mail:</div>
                            <Input type={'text'} placeholder={'Informe seu e-mail'} borderRange={'2px'} value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} borderColor={'#222323'} />
                            <ButtonPadrao backgroundColor={'#737d81'} text={'SOLICITAR E-MAIL'} onClick={handleActivateRegister} disabled={isLoadingActivated} />
                            {isLoadingActivated && (
                                <div className='div_modal_change_psw_loading'>
                                    <LoadingImageRotate loadingImage={loadingRosa}  altLoadingImage={'Loading image young'} width={'40px'} text={'C A R R E G A N D O'} />
                                </div>
                            )}
                        </div>
                        <div className='div_modal_activate_logo'>
                            <img className='div_modal_change_psw_logo' src={logoPreta} alt="Logo Young Preto" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={resetModalTokenPsw}>
                    Fechar
                    </Button>
                </Modal.Footer>
            </Modal>




            <Modal show={showModal} onHide={resetModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Recuperar senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='div_modal_change_psw'>
                        <div className='div_modal_change_psw_loading'>
                            <div className='div_login_page_div_textSimple'><b>Informe seu e-mail:</b></div>
                            <Input type={'text'} placeholder={'Informe seu e-mail'} borderRange={'2px'} value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} borderColor={'#222323'} />
                            <ButtonPadrao backgroundColor={'#737d81'} text={'SOLICITAR TOKEN'} onClick={handleNewPsw} disabled={isLoadingPsw} />
                            {isLoadingPsw && (
                                <div className='div_modal_change_psw_loading'>
                                    <LoadingImageRotate loadingImage={loadingRosa}  altLoadingImage={'Loading image young'} width={'40px'} text={'C A R R E G A N D O'} />
                                </div>
                            )}
                            {showDivTokenPsw && (
                                <div className='div_modal_change_psw_loading'>
                                    <div className='div_login_page_div_textSimple'><b>Informe o token:</b></div>
                                    <Input type={'text'} placeholder={'Informe o token'} borderRange={'2px'} value={tokenChangePsw} onChange={(e) => setTokenChangePsw(e.target.value)} borderColor={'#222323'} />
                                    <div className='div_login_page_div_textSimple'><b>Cadastre sua nova senha:</b></div>
                                    <Input type={'password'} placeholder={'Cadastre sua nova senha'} borderRange={'2px'} value={newPsw} onChange={(e) => setNewPsw(e.target.value)} borderColor={'#222323'} />
                                    <Input type={'password'} placeholder={'Informe novamente a senha'} borderRange={'2px'} value={newPswCompare} onChange={ConfirmEqualPsw} borderColor={'#222323'} />
                                    {passwordMatch && <div className='div_register_alert_text'><TextAlert text={'ATENÇÃO! As senhas informadas não conferem :('}/></div>}
                                    <ButtonPadrao backgroundColor={'#737d81'} text={'ALTERAR'} onClick={handleChangeNewPsw} disabled={''} />
                                </div>
                            )

                            }
                        </div>
                        <div>
                            <img className='div_modal_change_psw_logo' src={logoPreta} alt="Logo Young Preto" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={resetModal}>
                    Fechar
                    </Button>
                </Modal.Footer>
            </Modal>







            
        </div>
    )
}

export default EnzoLogin