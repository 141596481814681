import './button.css'
import React from 'react'

const ButtonPadrao = ({ backgroundColor, text, onClick, disabled}) => {
  const buttonStyle = {
    backgroundColor: backgroundColor,
    color: 'white',
    cursor: 'pointer',
    border: `2px solid ${backgroundColor}`
  };

  return <button onClick={onClick} disabled={disabled} className='button_padrao' style={buttonStyle}>{text}</button>
}

export default ButtonPadrao