// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div_menu_general{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:30px;
    padding-top: 36px;
    min-height: 100vh;
    width: 250px;
    justify-content: space-between;
}
.div_menu_general_buttons {
    display: flex;
    flex-direction: column;
    gap:16px;
  }
  
.div_menu_general_content {
    display: flex;
    flex-direction: column;
    gap:16px;
    align-items: flex-end;
}
.div_menu_general_none{
    height: 50%;
}


@media (max-width: 768px) {
    .div_menu_general{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/EnzoMenu/menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;;AAEF;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,qBAAqB;AACzB;AACA;IACI,WAAW;AACf;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".div_menu_general{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap:30px;\n    padding-top: 36px;\n    min-height: 100vh;\n    width: 250px;\n    justify-content: space-between;\n}\n.div_menu_general_buttons {\n    display: flex;\n    flex-direction: column;\n    gap:16px;\n  }\n  \n.div_menu_general_content {\n    display: flex;\n    flex-direction: column;\n    gap:16px;\n    align-items: flex-end;\n}\n.div_menu_general_none{\n    height: 50%;\n}\n\n\n@media (max-width: 768px) {\n    .div_menu_general{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
