import './StartPage.css'
import React from 'react'
import RoboImg from '../../components/RoboImg'
import robo from '../img/robo1.png'
import logoPreta from '../img/young_preto.png'
import Logo from '../../components/Logo'
import ButtonPadrao from '../../components/ButtonPadrao'
import { Link } from 'react-router-dom'


const StartPage = () => {
    return (
        <div className='div_init_page'>
            <div className='div_init_page_1'></div>
            <div className='div_init_page_2'>
                <div className='div_init_page_div_division'>
                    <div className='div_init_page_div_division_1'>
                        <div>
                            <RoboImg imageUrl={robo} alt={'Robo Enzo'} width={'120px'} />
                        </div>
                        <div className='div_init_page_div_acess'>
                            <div className='div_init_page_div_text'>
                                <b>Yan, o seu assistente virtual inteligente Young, sempre disponível para ajudá-lo.</b>
                            </div>
                            <div className='div_init_page_div_buttons'>
                                <Link to="/login"><div className='div_init_page_buttons'><ButtonPadrao backgroundColor={'#6051B7'} text={'LOGAR'}/></div></Link>
                                <Link to="/create"><div className='div_init_page_buttons'><ButtonPadrao backgroundColor={'#6051B7'} text={'CADASTRAR'}/></div></Link>
                            </div>
                        </div>
                        
                    </div>

                    <div className='div_init_page_div_division_2'>
                        <div><Logo width={'200px'} imageUrl={logoPreta} alt={'Logo Young cor Preto'} /></div>
                    </div>

                </div>
                
            </div>
        </div>
    )
}

export default StartPage