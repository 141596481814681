import React from 'react';
import './RoboImg.css'


const RoboImg = ({ width, imageUrl, alt }) => {
  return (
      <img
        src={imageUrl}
        alt={alt}
        style={{ width: width }}
    />
  );
};

export default RoboImg;