import React, { useState } from 'react'
import './menu.css'
import ButtonPadrao from '../ButtonPadrao'
import Logo from '../Logo'
import roboBranco from '../../pages/img/robo4.png'
import { Link } from 'react-router-dom'
import PhraseInsert from '../PhraseInsert'

function EnzoMenuOpen() {

  return (
    <div className='div_menu_general'>
        <div className='div_menu_general_buttons'>
            <Link to="/home"><ButtonPadrao backgroundColor={'#6051B7'} text={'HOME'}/></Link>
            <Link to="/companie"><ButtonPadrao backgroundColor={'#6051B7'} text={'COMPANIE'}/></Link>
            <Link to="/talk"><ButtonPadrao backgroundColor={'#6051B7'} text={'FALE COM YAN'}/></Link>
            <Link to="/showchats"><ButtonPadrao backgroundColor={'#6051B7'} text={'CHATS'}/></Link>
            <Link to="/logout"><ButtonPadrao backgroundColor={'#737d81'} text={'LOGOUT'}/></Link>
        </div>
        <div className='div_menu_general_none'></div>
        <div className='div_menu_general_content'>
            <PhraseInsert phraseInsert={'Personalize o Yan para atender às suas necessidades.'} />
            <Logo width={'200px'} imageUrl={roboBranco} alt={'RoboYoung cor Branco'} />
        </div>
    </div>
  )
}

export default EnzoMenuOpen
