import React, { useState } from 'react'
import './phrase.css'

function PhraseInsert({phraseInsert}) {

  return (
    <div className='div_phrase'><div><b>{phraseInsert}</b></div></div>
  )
}

export default PhraseInsert
